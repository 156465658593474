@import '../../../../kai-ui/src/theme/fonts.scss';
@import '../../../../kai-ui/src/theme/theme.scss';


.kai-tab-icon-view {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	&-tabs {
		flex-shrink: 0;
	}
	&-content {
		flex-grow: 1;
		height: calc(100% - 3rem);
		overflow: auto;
	}
}
