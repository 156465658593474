$icomoon-font-family: 'kai-icons' !default;
$icomoon-font-path: '../kai-font/fonts' !default;

$kai-icon-arrow: '\e900';
$kai-icon-checkbox-checked: '\e901';
$kai-icon-checkbox-unchecked: '\e902';
$kai-icon-radio-button-selected: '\e906';
$kai-icon-radio-button-unselected: '\e90f';
$kai-icon-bluetooth: '\e903';
$kai-icon-calendar: '\e904';
$kai-icon-camera: '\e905';
$kai-icon-contacts: '\e907';
$kai-icon-download: '\e908';
$kai-icon-email: '\e909';
$kai-icon-favorite-off: '\e90a';
$kai-icon-favorite-on: '\e90b';
$kai-icon-message: '\e90c';
$kai-icon-mic: '\e90d';
$kai-icon-phone: '\e90e';
$kai-icon-search: '\e910';
$kai-icon-settings: '\e911';
$kai-icon-video: '\e912';
$kai-icon-wifi: '\e913';
