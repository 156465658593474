.HyperLinkType{
	height: 3rem;
	border: 1px solid;
	border-radius: 6px;
	align-items: center;
	outline: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	box-sizing: border-box;
}