@import '../../HomePageATheme/theme.scss';

.seperatorLineA{
    width: 100%;
	height: 0rem;

	&-top{
		@extend .seperatorLineA;
		border: 1px solid var(--color-home-a-dark-grey);
		top: 3rem;
	}
	&-bottom{
		@extend .seperatorLineA;
		position: absolute;
		border: 1px solid var(--color-home-a-dark-grey);
		bottom: 3rem;
	}
	&-invisible{
		@extend .seperatorLineA;
		clear: both;
		visibility: hidden;
	}
}
