@import '../../theme/fonts.scss';
@import '../../theme/theme.scss';

:root {
  --btl-text-focused: var(--color-gs00);
}
.kai-btl {
  height: fit-content;
  width: 100%;
  min-height: var(--list-item-height);
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;

  span,
  label {
    padding: 0;
    width: 100%;
    overflow: hidden;
  }

  &:focus {
    color: var(--btl-text-focused);
  }

  &-header {
    @extend h2;
  }

  &-body {
    @extend p;
  }
}
