@import '../../../../kai-ui/src/theme/fonts.scss';
@import '../../../../kai-ui/src/theme/theme.scss';

.kai-tab-view-B {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  &-tabs {
    flex-shrink: 0;
    width:50%;
    position: absolute;
    z-index:1;
  }
  &-content {
      z-index:2;
      margin-top: 3rem;
      flex-grow: 1;
      height:calc(100%-3rem);
      overflow: auto;
  }
  
}
.divider{
  height:0.7px;
  background-color: #e4e4e4;
  width:100%;
  position:absolute;
  z-index:2;
  top:2.5rem;
}