@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?67hhep');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?67hhep#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?67hhep') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?67hhep') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?67hhep##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="kai-icon-"], [class*=" kai-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kai-icon-arrow {
  &:before {
    content: $kai-icon-arrow;     
    color: #fff;
  }
}
.kai-icon-checkbox-checked {
  &:before {
    content: $kai-icon-checkbox-checked;     
    color: #fff;
  }
}
.kai-icon-checkbox-unchecked {
  &:before {
    content: $kai-icon-checkbox-unchecked;     
    color: #fff;
  }
}
.kai-icon-radio-button-selected {
  &:before {
    content: $kai-icon-radio-button-selected;     
    color: #fff;
  }
}
.kai-icon-radio-button-unselected {
  &:before {
    content: $kai-icon-radio-button-unselected;     
    color: #fff;
  }
}
.kai-icon-bluetooth {
  &:before {
    content: $kai-icon-bluetooth;     
    color: #fff;
  }
}
.kai-icon-calendar {
  &:before {
    content: $kai-icon-calendar;     
    color: #fff;
  }
}
.kai-icon-camera {
  &:before {
    content: $kai-icon-camera;     
    color: #fff;
  }
}
.kai-icon-contacts {
  &:before {
    content: $kai-icon-contacts;     
    color: #fff;
  }
}
.kai-icon-download {
  &:before {
    content: $kai-icon-download;     
    color: #fff;
  }
}
.kai-icon-email {
  &:before {
    content: $kai-icon-email;     
    color: #fff;
  }
}
.kai-icon-favorite-off {
  &:before {
    content: $kai-icon-favorite-off;     
    color: #fff;
  }
}
.kai-icon-favorite-on {
  &:before {
    content: $kai-icon-favorite-on;     
    color: #fff;
  }
}
.kai-icon-message {
  &:before {
    content: $kai-icon-message;     
    color: #fff;
  }
}
.kai-icon-mic {
  &:before {
    content: $kai-icon-mic;     
    color: #fff;
  }
}
.kai-icon-phone {
  &:before {
    content: $kai-icon-phone;     
    color: #fff;
  }
}
.kai-icon-search {
  &:before {
    content: $kai-icon-search;     
    color: #fff;
  }
}
.kai-icon-settings {
  &:before {
    content: $kai-icon-settings;     
    color: #fff;
  }
}
.kai-icon-video {
  &:before {
    content: $kai-icon-video;     
    color: #fff;
  }
}
.kai-icon-wifi {
  &:before {
    content: $kai-icon-wifi;     
    color: #fff;
  }
}

