*{  
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: normal;
}

.mainDiv{
    height:100vh;
    width:100vw;
    background-color:#F2F2F2;
}