@import '../../../../kai-ui/src/theme/fonts.scss';
@import '../../../../kai-ui/src/theme/theme.scss';

:root {
	--tab-background-color: var(--color-gs00);
}

.kai-tab-i-active {
	outline: none;
	text-align: center;
	background-color: var(--tab-background-color);
	width: 100%;
	height: 0.35rem;
	// border-radius: 8px;
	margin-bottom: 0.2rem;
	box-shadow: 0 2.8rem 0 0 var(--tab-underline-color);

	&-img {
		margin: 0 0.5rem;
		padding: 3px;
		overflow: hidden;
		height: 2.5rem;
	}
}

.kai-tab-i-inactive {
	outline: none;
	text-align: center;
	background-color: var(--tab-background-color);
	width: 100%;
	height: 0.2rem;
	border-radius: 6px;
	margin-bottom: 0.2rem;

	&-img {
		margin: 0 0.5rem;
		padding: 3px;
		overflow: hidden;
		height: 2.5rem;
	}
}
