@import '../../theme/fonts.scss';
@import '../../theme/theme.scss';
@import '../../theme/layout';
@import '../../kai-font/style.scss';

:root {
  --al-text-focused: var(--color-gs00);
  --al-icon-unfocused: var(--color-zeta);
}
.kai-al {
  width: auto;
  min-height: var(--list-item-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  outline: none;
  &:focus {
    color: var(--al-text-focused);
  }

  &-icon {
    &-focused {
      span::before {
        @include list-item-arrow;
        color: var(--al-text-focused);
      }
    }

    &-unfocused {
      span::before {
        @include list-item-arrow;
        color: var(--al-icon-unfocused);
      }
    }
  }

  &-line {
    font-family: 'Open Sans';
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 1rem 0 1rem 1rem;
    span,
    label {
      padding: 0;
      width: 100%;
      overflow: hidden;
    }
  }

  &-primary {
    @extend .p-pri;
  }

  &-secondary {
    @extend .p-sec;
  }
}
