@import '../../theme/fonts.scss';
@import '../../theme/theme.scss';

:root {
  --tl-text-focused: var(--color-gs00);
}
.kai-tl {
  height: fit-content;
  width: 100%;
  min-height: var(--list-item-height);
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;

  span,
  label {
    padding: 0;
    width: 100%;
    overflow: hidden;
  }

  &:focus {
    color: var(--tl-text-focused);
  }

  &-primary {
    @extend .p-pri;
  }

  &-secondary {
    @extend .p-sec;
  }

  &-tertiary {
    @extend .p-thi;
  }
}
