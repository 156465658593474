@import '../../../../kai-ui/src/theme/fonts.scss';
@import '../../../../kai-ui/src/theme/theme.scss';
@import '../../HomePageBTheme/layout';

:root {
	--tab-background-color: var(--color-gs00);
}

.kai-tab-B-active {
	outline: none;
	text-align: center;
	background-color: var(--tab-background-color);
	width: 100%;
	height: 0.4rem;
	//border-radius: 8px;
	margin-bottom: 0.2rem;
	box-shadow: 0 2.54rem 0 0 var(--tab-underline-color);

	&-label {
		@extend h3;
		margin: 0 0.5rem;
		overflow: hidden;
		height: 3rem;
		line-height: 3rem;
		font-weight: 900;
	}
}

.kai-tab-B-inactive {
	outline: none;
	text-align: center;
	background-color: var(--tab-background-color);
	width: 100%;
	height: 0.2rem;
	border-radius: 6px;
	margin-bottom: 0.2rem;

	&-label {
		@extend h3;
		margin: 0 0.5rem;
		overflow: hidden;
		height: 3rem;
		line-height: 3rem;
		font-weight: 200;
	}
}
