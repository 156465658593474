@import '../../HomePageATheme/theme.scss';

.gridContainerA{
    position: absolute;
    top: 4.5rem;
	bottom: 4.5rem;
	right: 1rem;
	left: 1rem;
	box-sizing: border-box;
    background: var(--color-home-a-light-grey);
}

/*
Use If Flex-wrap is not supported on kaiOS

.gridContainerA{
	position: absolute;
	display: flex;
    top: 4.5rem;
	bottom: 4.5rem;
	right: 1rem;
	left: 1rem;
	box-sizing: border-box;
	background: var(--color-home-a-light-grey);
	overflow: auto;
}

*/