.footer-B{
    font-family: 'Noto Sans', sans-serif;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height:3rem;
}
.Page-Content-B{
    font-family: 'Noto Sans', sans-serif;
    height:100vh;
}
