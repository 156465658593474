@import '../../../../kai-ui/src/theme/fonts.scss';
@import '../../../../kai-ui/src/theme/theme.scss';
@import '../../../../kai-ui/src/theme/layout.scss';

$kai-header-height: 2.8rem;

.kai-header-navpage {
  @include flex(row);
  width: 100%;
  line-height: $kai-header-height;
  outline: none;
  top: 0;
  position: fixed;
  height: 3rem;

  h1 {
    width: 100%;
    height: 100%;
    //color: var(--color-gs00, #fff);
    @extend %text-ellipsis;
    @extend h1;
    text-align: center;
    padding: 0 1rem;
    margin: 0;
  }
}
